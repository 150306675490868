import React from "react"
import Layout from "./../components/layouts/Default"
import Container from "../components/layouts/container/Container"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Nachricht gesendet | Döbbel Immobilien"
      desc=""
    >
      <Container className="my-8 md:my-16">
        <div className="max-w-3xl p-5 mx-auto">
          <div className="prose">
            <h1>Nachricht gesendet</h1>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
